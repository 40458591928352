"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CSRFContextProvider = exports.CSRFContext = void 0;
const react_1 = __importStar(require("react"));
exports.CSRFContext = (0, react_1.createContext)({
    csrfToken: '',
    csrfFetch: async () => {
        throw new Error('csrfFetch function not initialized');
    },
    callApi: async () => {
        throw new Error('callApi function not initialized');
    },
});
async function fetchWithTimeout(resource, options = {}) {
    const { timeout = 10000 } = options;
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const response = await fetch(resource, {
        ...options,
        signal: controller.signal,
    });
    clearTimeout(id);
    return response;
}
const CSRFContextProvider = ({ children, csrfToken }) => {
    const csrfFetch = (0, react_1.useCallback)(async (resource, init) => {
        if (init) {
            init.credentials = init.credentials || 'same-origin';
        }
        if (csrfToken !== null && init) {
            init.headers = {
                ...init.headers,
                'CSRF-Token': csrfToken,
            };
        }
        return fetchWithTimeout(resource, init);
    }, [csrfToken]);
    const callApi = (0, react_1.useCallback)(async (route, body) => {
        return csrfFetch(route, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }, [csrfFetch]);
    return react_1.default.createElement(exports.CSRFContext.Provider, { value: { csrfToken, csrfFetch, callApi } }, children);
};
exports.CSRFContextProvider = CSRFContextProvider;
