"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COLORS = void 0;
exports.arraysEqual = arraysEqual;
exports.arrayRotate = arrayRotate;
exports.arrayShuffle = arrayShuffle;
exports.arrayMove = arrayMove;
exports.arrayDelete = arrayDelete;
exports.arrayIsSubset = arrayIsSubset;
exports.arraysAreEqualSets = arraysAreEqualSets;
exports.randomElement = randomElement;
exports.fromEntries = fromEntries;
exports.deepCopy = deepCopy;
exports.alphaCompare = alphaCompare;
exports.cmcColumn = cmcColumn;
exports.sortDeck = sortDeck;
exports.isTouchDevice = isTouchDevice;
exports.getCubeId = getCubeId;
exports.getCubeCardCountSnippet = getCubeCardCountSnippet;
exports.getCubeCategoryDescriptionSnippet = getCubeCategoryDescriptionSnippet;
exports.getCubeDescription = getCubeDescription;
exports.isInternalURL = isInternalURL;
exports.toNullableInt = toNullableInt;
exports.isSamePageURL = isSamePageURL;
exports.getCardColorClass = getCardColorClass;
exports.getCardTagColorClass = getCardTagColorClass;
exports.getTagColorClass = getTagColorClass;
exports.wait = wait;
exports.xorStrings = xorStrings;
const cardutil_1 = require("./cardutil");
function arraysEqual(a, b) {
    if (a === b)
        return true;
    if (!Array.isArray(a) || !Array.isArray(b))
        return false;
    if (a.length !== b.length)
        return false;
    for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i])
            return false;
    }
    return true;
}
function arrayRotate(arr, reverse) {
    if (reverse)
        arr.unshift(arr.pop());
    else
        arr.push(arr.shift());
    return arr;
}
function arrayShuffle(array) {
    let currentIndex = array.length;
    let temporaryValue;
    let randomIndex;
    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }
    return array;
}
function arrayMove(arr, oldIndex, newIndex) {
    const result = [...arr];
    const [element] = result.splice(oldIndex, 1);
    result.splice(newIndex, 0, element);
    return result;
}
function arrayDelete(arr, index) {
    const result = [...arr];
    result.splice(index, 1);
    return result;
}
function arrayIsSubset(needles, haystack, comparison) {
    if (comparison) {
        return needles.every((elem) => haystack.some((elem2) => comparison(elem, elem2)));
    }
    return needles.every((x) => haystack.includes(x));
}
function arraysAreEqualSets(a1, a2, comparison) {
    if (a1.length !== a2.length) {
        return false;
    }
    if (comparison) {
        return (a1.every((elem) => a2.some((elem2) => comparison(elem, elem2))) &&
            a2.every((elem) => a1.some((elem2) => comparison(elem, elem2))));
    }
    const set1 = new Set(a1);
    const set2 = new Set(a2);
    return a1.every((x) => set2.has(x)) && a2.every((x) => set1.has(x));
}
function randomElement(array) {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
}
function fromEntries(entries) {
    const obj = {};
    for (const [k, v] of entries) {
        obj[k] = v;
    }
    return obj;
}
function deepCopy(x) {
    return JSON.parse(JSON.stringify(x));
}
function alphaCompare(a, b) {
    const textA = a.details.name.toUpperCase();
    const textB = b.details.name.toUpperCase();
    return textA.localeCompare(textB);
}
function cmcColumn(card) {
    const cmc = (0, cardutil_1.cardCmc)(card);
    // Round to half-integer then take ceiling to support Little Girl
    const cmcDoubleInt = Math.round(cmc * 2);
    let cmcInt = Math.round((cmcDoubleInt + (cmcDoubleInt % 2)) / 2);
    if (cmcInt < 0) {
        cmcInt = 0;
    }
    if (cmcInt > 7) {
        cmcInt = 7;
    }
    return cmcInt;
}
function sortInto(card, result) {
    const typeLine = (0, cardutil_1.cardType)(card).toLowerCase();
    const row = typeLine.includes('creature') ? 0 : 1;
    const column = cmcColumn(card);
    if (result[row][column].length === 0) {
        result[row][column] = [card];
    }
    else {
        result[row][column].push(card);
    }
}
function sortDeck(deck) {
    const result = [new Array(8).fill([]), new Array(8).fill([])];
    for (const item of deck) {
        if (Array.isArray(item)) {
            for (const card of item) {
                sortInto(card, result);
            }
        }
        else {
            sortInto(item, result);
        }
    }
    return result;
}
exports.COLORS = [
    ['White', 'W'],
    ['Blue', 'U'],
    ['Black', 'B'],
    ['Red', 'R'],
    ['Green', 'G'],
];
function isTouchDevice() {
    // https://stackoverflow.com/questions/4817029/whats-the-best-way-to-detect-a-touch-screen-device-using-javascript
    if (typeof window === 'undefined') {
        return false;
    }
    const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    const mq = (query) => window.matchMedia(query).matches;
    if (Object.prototype.hasOwnProperty.call(window, 'ontouchstart') ||
        // @ts-expect-error This will be tricky to typecheck
        (window.DocumentTouch && document instanceof window.DocumentTouch)) {
        return true;
    }
    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    return mq(query);
}
function getCubeId(cube) {
    return cube.shortId || cube.id;
}
function getCubeCardCountSnippet(cube, changedCards) {
    const cardCount = changedCards && changedCards.mainboard && changedCards.mainboard?.length > 0
        ? changedCards.mainboard.length
        : cube.cardCount;
    return `${cardCount} Card`;
}
function getCubeCategoryDescriptionSnippet(cube) {
    const overridePrefixes = cube.categoryPrefixes && cube.categoryPrefixes.length > 0 ? `${cube.categoryPrefixes.join(' ')} ` : '';
    if (cube.categoryOverride) {
        return `${overridePrefixes}${cube.categoryOverride} Cube`;
    }
    return `${overridePrefixes}Cube`;
}
function getCubeDescription(cube, changedCards) {
    return `${getCubeCardCountSnippet(cube, changedCards)} ${getCubeCategoryDescriptionSnippet(cube)}`;
}
function isInternalURL(to) {
    try {
        const url = new URL(to, window.location.origin);
        return url.hostname === window.location.hostname;
    }
    catch {
        return false;
    }
}
function toNullableInt(str) {
    const val = parseInt(str, 10);
    return Number.isInteger(val) ? val : null;
}
function isSamePageURL(to) {
    try {
        const url = new URL(to, window.location.href);
        return (url.hostname === window.location.hostname &&
            url.pathname === window.location.pathname &&
            url.search === window.location.search);
    }
    catch {
        return false;
    }
}
const colorToColorClass = {
    White: 'white',
    Blue: 'blue',
    Black: 'black',
    Red: 'red',
    Green: 'green',
    Colorless: 'colorless',
    Multicolored: 'multi',
    Hybrid: 'multi',
    Lands: 'lands',
};
function getCardColorClass(card) {
    if (!card) {
        return 'colorless';
    }
    return colorToColorClass[(0, cardutil_1.cardColorIdentityCategory)(card)];
}
function getCardTagColorClass(tagColors, card) {
    if (tagColors) {
        const tagColor = tagColors.find(({ tag }) => (card.tags || []).includes(tag));
        if (tagColor && tagColor.color && tagColor.color !== 'no-color' && tagColor.color !== 'None') {
            return `tag-color tag-${tagColor.color}`;
        }
    }
    return getCardColorClass(card);
}
function getTagColorClass(tagColors, tag) {
    const tagColor = tagColors.find((tagColorB) => tag === tagColorB.tag);
    if (tagColor && tagColor.color && tagColor.color !== 'no-color') {
        return `tag-color tag-${tagColor.color}`;
    }
    return '';
}
async function wait(ms) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}
function xor(a, b) {
    let result = '';
    for (let i = 0; i < Math.min(a.length, b.length); i++) {
        result += String.fromCharCode(a.charCodeAt(i) ^ b.charCodeAt(i));
    }
    return result;
}
function xorStrings(strings) {
    const nonNullStrings = strings.filter((str) => str !== null);
    if (nonNullStrings.length === 0) {
        return '';
    }
    let result = nonNullStrings[0];
    for (let i = 1; i < nonNullStrings.length; i++) {
        result = xor(result, nonNullStrings[i]);
    }
    return result;
}
exports.default = {
    arraysEqual,
    arrayRotate,
    arrayShuffle,
    arrayMove,
    arrayDelete,
    arrayIsSubset,
    arraysAreEqualSets,
    randomElement,
    fromEntries,
    alphaCompare,
    cmcColumn,
    sortDeck,
    COLORS: exports.COLORS,
    getCubeId,
    getCubeDescription,
    isInternalURL,
    toNullableInt,
    isSamePageURL,
    getCardColorClass,
    getCardTagColorClass,
    getTagColorClass,
    wait,
    xorStrings,
};
