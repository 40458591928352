"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useToggle = (def) => {
    const [value, setValue] = (0, react_1.useState)(def);
    const toggle = (0, react_1.useCallback)(() => setValue((val) => !val), [setValue]);
    const enable = (0, react_1.useCallback)(() => setValue(true), [setValue]);
    const disable = (0, react_1.useCallback)(() => setValue(false), [setValue]);
    return [value, toggle, enable, disable];
};
exports.default = useToggle;
